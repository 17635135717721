<template>
    <v-container class="mt-3 pl-3 pr-3 pb-3">
        <v-row class="pt-4">
            <v-col cols="12" lg="3" xl="3">
                <v-card elevation="2" outlined>
                    <v-card-title>Kategorien</v-card-title>
                    <v-card-text>Hier können Sie die Kategorien bearbeiten, ändern oder auch neu anlegen.</v-card-text>
                    <v-btn :to="{name: 'categoryList'}" class="mb-4 ml-4" color="primary">Weiter</v-btn>
                </v-card>
            </v-col>
            <v-col cols="12" lg="3" xl="3">
                <v-card elevation="2" outlined>
                    <v-card-title>Produkte</v-card-title>
                    <v-card-text>Hier können Sie die Produkte bearbeiten, ändern oder auch neu anlegen.</v-card-text>
                    <v-btn :to="{name: 'productList'}" class="mb-4 ml-4" color="primary">Weiter</v-btn>
                </v-card>
            </v-col>
            <v-col cols="12" lg="3" xl="3">
                <v-card elevation="2" outlined>
                    <v-card-title>Über mich</v-card-title>
                    <v-card-text>Hier können Sie die Über mich bearbeiten, ändern oder auch neu anlegen.</v-card-text>
                    <v-btn :to="{name: 'aboutMe'}" class="mb-4 ml-4" color="primary">Weiter</v-btn>
                </v-card>
            </v-col>
            <v-col cols="12" lg="3" xl="3">
                <v-card elevation="2" outlined>
                    <v-card-title>Benutzer</v-card-title>
                    <v-card-text>Hier können Sie die Benutzer bearbeiten, ändern oder auch neu anlegen.</v-card-text>
                    <v-btn :to="{name: 'userList'}" class="mb-4 ml-4" color="primary">Weiter</v-btn>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// ==================== [CSS] ===============================

// ==================== [JS] ================================

// ==================== [VUE COMPONENTS] ====================

export default {
    name: "Dashboard",
    data(){
        return {

        }
    }
}
</script>

<style scoped>

</style>