<template>
    <v-container class="pl-3 pr-3">
        <v-row>
            <v-col cols="12" xl="3" offset-xl="4">
                <v-card class="mt-15">
                    <v-card-title id="cardTitle" class="mb-5">
                        Login
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="form" v-model="formValid" @submit.prevent="submit()">
                            <v-container>
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <v-alert v-if="formError" type="error">Benutzername oder Passwort falsch!</v-alert>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-text-field v-model="username" :rules="usernameRules" label="Benutzername"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-text-field type="password" v-model="password" :rules="passwordRules" label="Passwort"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-row>
                                <v-col class="text-right" cols="12" xl="12">
                                    <v-btn color="primary" rounded outlined x-large type="submit">Login</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// ==================== [CSS] ===============================

// ==================== [JS] ================================

// ==================== [VUE COMPONENTS] ====================

import CommonApi from "@/common/api/CommonApi";
import LoginManager from "@/common/utils/security/LoginManager";

export default {
    name: "Login",
    data() {
        return {
            formError: false,
            formValid: false,
            username: '',
            usernameRules: [
                v => !!v || 'Bitte geben Sie Ihren Benutzername ein',
            ],
            password: '',
            passwordRules: [
                v => !!v || 'Bitte geben Sie ein Passwort ein',
            ],
        }
    },
    methods: {
        async submit() {
            if(!this.$refs.form.validate())
                return null;

            const response = await this.axios({
                method: 'POST',
                url: CommonApi.loginAuth(),
                data: {
                    username: this.$data.username,
                    password: this.$data.password,
                },
            }).catch(() => {
                this.$data.formError = true
                setTimeout(() => {
                    this.$data.formError = false
                }, 3000)
            });

            if(response !== undefined && response.status === 200) {
                LoginManager.setAccessToken(response.data.accessToken);
                await this.$router.push({name: "dashboard"});
            }
        }
    },
    created() {
        if(LoginManager.isLoggedIn())
            this.$router.push({name: "dashboard"});
    }
}
</script>

<style scoped>

</style>