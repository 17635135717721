import Vue from 'vue';
import App from './App.vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Cookies from 'js-cookie';
import vuetify from './plugins/vuetify';
import router from './common/router';
import store from './common/store';
import VueSweetalert2 from 'vue-sweetalert2';

Vue.use(VueSweetalert2);

Vue.config.productionTip = false;

axios.interceptors.request.use((config) => {
    const accessToken = Cookies.get('accessToken');
    if(accessToken)
        config.headers['Authorization'] = `Bearer ${accessToken}`;
    return config;
});

Array.prototype.removeItem = function (value) {
    const index = this.indexOf(value);
    if (index > -1)
        this.splice(index, 1);
};

Vue.use(VueAxios, axios);

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App),
}).$mount('#app');