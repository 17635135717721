<template>
  <v-container class="mt-5 pl-3 pr-3 pb-3">
    <v-row>
      <v-col cols="12" xl="6">
        <h1 class="font-weight-regular">Kategorien</h1>
      </v-col>
      <v-col cols="12" xl="6" class="text-right">
        <category-create/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field background-color="#ffffff" v-model="search" append-icon="mdi-magnify" label="Suche" single-line
                      hide-details outlined/>
      </v-col>
    </v-row>
    <v-card class="mt-5">
      <v-data-table :items="categoryList" :headers="headers" :search="search" :loading="isLoading"
                    loading-text="Daten werden geladen...">
        <template v-slot:item.actions="{item}">
          <v-menu left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">
                mdi-dots-vertical
              </v-icon>
            </template>
            <v-list>
              <category-edit :id="item.id"/>
              <v-list-item @click="deleteCategory(item)">
                <v-list-item-title>Löschen</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar timeout="2000" v-model="showSnackbar">{{ snackBarText }}</v-snackbar>
  </v-container>
</template>

<script>
// ==================== [CSS] ===============================

// ==================== [JS] ================================
import SessionApi from "@/common/api/SessionApi";
import CategoryCreate from "@/components/session/category/CategoryCreate";
import {eventBus} from "@/common/eventBus";
import CategoryEdit from "@/components/session/category/CategoryEdit";
import SweetAlertConfig from "@/common/utils/SweetAlertConfig";
// ==================== [VUE COMPONENTS] ====================

export default {
  name: "Category",
  components: {CategoryEdit, CategoryCreate},
  data() {
    return {
      showSnackbar: false,
      snackBarText: '',
      search: "",
      categoryList: [],
      isLoading: true,
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "",
          value: "actions",
          cellClass: "actionColumn",
          sortable: false,
        }
      ]
    }
  },
  methods: {
    async getCategoryList() {
      const response = await this.axios({
        method: 'GET',
        url: SessionApi.category(),
      }).catch(() => {

      });

      if (response !== undefined && response.status === 200) {
        this.$data.categoryList = response.data;
        this.$data.isLoading = false;
      }
    },
    deleteCategory(category) {
      this.$swal.fire({
        title: 'Kategorie löschen',
        html: `Sind Sie sich sicher das Sie <b>${category.name}</b> löschen möchten?`,
        ...SweetAlertConfig.delete(),
        preConfirm: async () => {
          const response = await this.axios({
            method: 'DELETE',
            url: SessionApi.category(category.id),
          }).catch(() => {

          });
          if (response !== undefined && response.status === 200) {
            await this.getCategoryList();
            this.$data.snackBarText = 'Erfogreich gelöscht';
            this.$data.showSnackbar = true;
          }
        }
      });
    }
  },
  created() {
    this.getCategoryList();
  },
  mounted() {
    eventBus.$on('categoryForm', (text) => {
      this.getCategoryList();
      this.$data.snackBarText = text;
      this.$data.showSnackbar = true;
    });
  }
}
</script>

<style scoped>

</style>