import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import de from "vuetify/src/locale/de.ts";

Vue.use(Vuetify);

const opts = {
    icons: {
        iconfont: "mdiSvg", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    lang: {
        locales: {de},
        current: "de",
    },
};

export default new Vuetify(opts);
