<template>
    <vue-editor :placeholder="placeholder" :id="editorId" @text-change="handleChange()" :editorToolbar="toolbarConfig" v-model="editorValue"></vue-editor>
</template>

<script>
// ==================== [CSS] ===============================
import "quill/dist/quill.snow.css";
// ==================== [JS] ================================

// ==================== [VUE COMPONENTS] ====================
import {VueEditor} from "vue2-editor";

export default {
    name: "GeoEditor",
    components: {
        VueEditor,
    },
    props: {
        'value': {
            required: true
        },
        'toolbar': {
            default: true
        },
        'id': {},
        'placeholder': {
            default: 'Beschreibung'
        },
    },
    watch: {
        value() {
            this.$data.editorValue = this.$props.value;
        }
    },
    data() {
        return {
            editorValue: this.$props.value,
            toolbarConfig: [""],
            editorId: this.$props.id ? this.$props.id : "editor",
        }
    },
    created() {
        if(this.$props.toolbar) {
            this.$data.toolbarConfig = [
                [{header: [1, 2, 3, 4, 5, 6, false]}],
                ["bold", "italic", "underline"],
                [{"list": "ordered"}, {"list": "bullet"}],
                [{"color": []}, {"background": []}],
                [{"align": []}],
                ["link"],
            ];
        }
    },
    methods: {
        handleChange() {
            this.$emit("input", this.$data.editorValue);
        }
    }
}
</script>

<style scoped>

</style>