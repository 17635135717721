<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" outlined rounded x-large v-bind="attrs" v-on="on" @click="renderCaptcha()">
                Anfrageformular
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="modalTitle">
                <span class="text-h5">Produkt: {{ product.name }}</span>
            </v-card-title>
            <v-form ref="form" v-model="formValid" @submit.prevent="submit()">
                <v-card-text class="mt-3">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field label="Vorname*" v-model="firstname" :rules="firstnameRules" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field label="Nachname*" v-model="lastname" :rules="lastnameRules" required></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field label="Email*" v-model="email" :rules="emailRules" required></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea label="Nachricht*" v-model="message" :rules="messageRules" required></v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field class="mt-5" :id="`jCaptcha_${product.id}`" label="Ergebnis" required :rules="captchaRules" :error-messages="captchaError"/>
                            </v-col>
                        </v-row>
                    </v-container>
                    <small>* das sind Pflichtfelder und müssen ausgefüllt werden.</small>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mb-3" color="blue darken-1" outlined rounded @click="dialog = false">
                        Abbrechen
                    </v-btn>
                    <v-btn class="mb-3" color="blue darken-1" outlined rounded type="submit">
                        Senden
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
// ==================== [CSS] ===============================

// ==================== [JS] ================================

// ==================== [VUE COMPONENTS] ====================

import CommonApi from "@/common/api/CommonApi";
import JCaptcha from "js-captcha";


export default {
    name: "modal",
    props: ['product'],
    data() {
        return {
            formValid: false,
            dialog: false,
            firstname: '',
            firstnameRules: [
                v => !!v || 'Bitte geben Sie Ihren Vorname ein',
            ],
            lastname: '',
            lastnameRules: [
                v => !!v || 'Bitte geben Sie Ihren Nachname ein',
            ],
            email: '',
            emailRules: [
                v => !!v || 'Bitte geben Sie eine Email an',
                v => /.+@.+/.test(v) || 'Bitte geben Sie eine Richtige E-Mail an',
            ],
            message: '',
            messageRules: [
                v => !!v || 'Bitte geben Sie Ihre Nachricht ein',
            ],
            captcha: null,
            captchaValid: false,
            captchaRules: [
                v => !!v || 'Bitte tragen Sie ein Ergebnis ein',
            ],
            captchaError: [],
        }
    },
    methods: {
        async submit() {
            this.$data.captcha.validate();
            if(!this.$refs.form.validate() || !this.$data.captchaValid)
                return null;

            const response = await this.axios({
                method: 'POST',
                url: CommonApi.productContact(),
                data: {
                    id: this.$props.product.id,
                    firstname: this.$data.firstname,
                    lastname: this.$data.lastname,
                    email: this.$data.email,
                    message: this.$data.message,
                },
            }).catch(() => {

            });

            if(response.status === undefined || response.status === 200) {
                this.$swal({
                    title: 'Anfrage erfolgreich versendet',
                    icon: "success",
                    confirmButtonColor: "#a5dc86",
                    preConfirm: () => {
                        this.$refs.form.reset();
                        this.$data.dialog = false;
                    }
                });
            }
        },
        renderCaptcha() {
            if(this.$data.captcha != null) {
                this.$data.captcha.reset();
                return null;
            }
            const interval = setInterval(() => {
                const el = document.getElementById(`jCaptcha_${this.$props.product.id}`);
                if(el !== undefined) {
                    clearInterval(interval);
                    this.$data.captcha = new JCaptcha({
                        el: `#jCaptcha_${this.$props.product.id}`,
                        canvasClass: `jCaptchaCanvas_${this.$props.product.id}`,
                        canvasStyle: {
                            width: 100,
                            height: 30,
                            textBaseline: "top",
                            font: "20px Roboto, sans-serif",
                            textAlign: "left",
                            fillStyle: "#333",
                        },
                        requiredValue: "",
                        callback: (response, $captchaInputElement, numberOfTries) => {
                            this.$data.captchaError = [];
                            if(response === "success") {
                                this.$data.captchaValid = true;
                            } else {
                                this.$data.captchaValid = false;
                                this.$data.captchaError.push("Das Ergebnis ist nicht korrekt");
                            }
                        }
                    });
                }
            }, 50);
        }
    },
}
</script>

<style scoped>

</style>