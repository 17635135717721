<template>
    <v-container class="mt-5 pl-3 pr-3 pb-3">
        <h1>Impressum</h1>
        <p>
            Odenwaldstraße 5<br/>
            64646 Heppenheim (Bergstraße), Hessen<br/>
            <br/>
            Tel.: +49 6252 966481<br/>
            Fax: +49 6252 966482<br/>
            Mobiltelefon: +49 160 8042159<br/>
            E-mail: ws@geo-agentur.de<br/>
            <br/>
            Ust-Id.-Nr.: DE230193593
        </p>
        <h2>1. Haftungsbeschränkung</h2>
        <p>
            Die Inhalte dieser Website werden mit größtmöglicher Sorgfalt erstellt. Der Anbieter übernimmt jedoch keine Gewähr für die Richtigkeit, Vollständigkeit und Aktualität der bereitgestellten
            Inhalte. Die Nutzung der Inhalte der Website erfolgt auf eigene Gefahr des Nutzers. Namentlich gekennzeichnete Beiträge geben die Meinung des jeweiligen Autors und nicht immer die Meinung
            des Anbieters wieder. Mit der reinen Nutzung der Website des Anbieters kommt keinerlei Vertrags- verhältnis zwischen dem Nutzer und dem Anbieter zustande.
        </p>
        <h2>2. Externe Links</h2>
        <p>
            Diese Website enthält Verknüpfungen zu Websites Dritter ("externe Links"). Diese Websites unterliegen der Haftung der jeweiligen Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung
            der externen Links die fremden Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße bestehen. Zu dem Zeitpunkt waren keine Rechtsverstöße ersichtlich. Der Anbieter hat keinerlei Einfluss
            auf die aktuelle und zukünftige Gestaltung und auf die Inhalte der verknüpften Seiten. Das Setzen von externen Links bedeutet nicht, dass sich der Anbieter die hinter dem Verweis oder Link
            liegenden Inhalte zu Eigen macht. Eine ständige Kontrolle der externen Links ist für den Anbieter ohne konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von zumutbar. Bei
            Kenntnis von Rechtsverstößen werden jedoch derartige externe Links unverzüglich gelöscht.
        </p>
        <h2>3. Urheber- und Leistungsschutzrechte</h2>
        <p>
            Die auf dieser Website veröffentlichten Inhalte unterliegen dem deutschen Urheber- und Leistungsschutzrecht. Jede vom deutschen Urheber- und Leistungsschutzrecht nicht zugelassene
            Verwertung bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen Rechteinhabers. Dies gilt insbesondere für Vervielfältigung, Bearbeitung, Übersetzung,
            Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen. Inhalte und Rechte Dritter sind dabei als solche gekennzeichnet.
            Die unerlaubte Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht gestattet und strafbar. Lediglich die Herstellung von Kopien und Downloads für den
            persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt.

            Die Darstellung dieser Website in fremden Frames ist nur mit schriftlicher Erlaubnis zulässig.
        </p>
        <h2>4. Datenschutz</h2>
        <p>
            Durch den Besuch der Website des Anbieters können Informationen über den Zugriff (Datum, Uhrzeit, betrachtete Seite) gespeichert werden. Diese Daten gehören nicht zu den personenbezogenen
            Daten, sondern sind anonymisiert. Sie werden ausschließlich zu statistischen Zwecken ausgewertet. Eine Weitergabe an Dritte, zu kommerziellen oder nichtkommerziellen Zwecken, findet nicht
            statt.

            Der Anbieter weist ausdrücklich darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen und nicht lückenlos vor dem Zugriff
            durch Dritte geschützt werden kann.

            Die Verwendung der Kontaktdaten des Impressums zur gewerblichen Werbung ist ausdrücklich nicht erwünscht, es sei denn der Anbieter hatte zuvor seine schriftliche Einwilligung erteilt oder
            es besteht bereits eine Geschäftsbeziehung. Der Anbieter und alle auf dieser Website genannten Personen widersprechen hiermit jeder kommerziellen Verwendung und Weitergabe ihrer Daten.
        </p>
        <h2>5. Besondere Nutzungsbedingungen</h2>
        <p>
            Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von den vorgenannten Nummern 1. bis 4. abweichen, wird an entsprechender Stelle ausdrücklich darauf hingewiesen. In
            diesem Falle gelten im jeweiligen Einzelfall die besonderen Nutzungsbedingungen.
        </p>
    </v-container>
</template>

<script>
// ==================== [CSS] ===============================

// ==================== [JS] ================================

// ==================== [VUE COMPONENTS] ====================

export default {
    name: "Imprint"
}
</script>

<style scoped>

</style>