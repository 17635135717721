export default class CommonApi {

    static aboutMe() {
        return `${CommonApi.ROOT_URL}/common/about-me`;
    }

    static contactForm() {
        return `${CommonApi.ROOT_URL}/common/contact-form`;
    }

    static productList() {
        return `${CommonApi.ROOT_URL}/common/product-list`;
    }

    static categories() {
        return `${CommonApi.ROOT_URL}/common/categories`;
    }

    static productContact() {
        return `${CommonApi.ROOT_URL}/common/product-contact`;
    }

    static loginAuth() {
        return `${CommonApi.ROOT_URL}/login/auth`;
    }
}

CommonApi.ROOT_URL = process.env.NODE_ENV === "production" ? window.location.origin : "http://localhost:8000";
