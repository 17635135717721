<template>
    <v-footer color="primary lighten-1" :padless="padless" v-bind="localAttrs">
        <v-row justify="center" no-gutters>
            <v-col class="primary py-4 text-center white--text" cols="12">
            <router-link class="mr-3 white--text" :to="{name: routerLink.name}" v-for="(routerLink, index) in routerLinks" :key="index" text rounded>
                {{ routerLink.title }}
            </router-link>
            </v-col>
            <v-col class="primary py-4 text-center white--text" cols="12">
                {{ new Date().getFullYear() }} — <strong>Geo-Agentur</strong>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
// ==================== [CSS] ===============================

// ==================== [JS] ================================

// ==================== [VUE COMPONENTS] ====================

export default {
    name: "Footer",
    data() {
        return {
            routerLinks: [
                {
                   title: 'Login',
                   name: 'login'
                },
                {
                    title: 'Datenschutz',
                    name: 'privacy'
                },
                {
                    title: 'Impressum',
                    name: 'imprint'
                },
            ],
            padless: true,
            variant: 'default',
        }
    },
    computed: {
        localAttrs () {
            const attrs = {}

            if (this.variant === 'default') {
                attrs.absolute = false
                attrs.fixed = false
            } else {
                attrs[this.variant] = true
            }
            return attrs
        },
    },
}
</script>

<style scoped>

</style>