<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" large v-bind="attrs" v-on="on">
        Kategorie erstellen
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5 white--text primary">
        Neue Kategorie erstellen
      </v-card-title>
      <v-form ref="form" v-model="formValid" @submit.prevent="submit()">
        <v-card-text>
          <v-text-field v-model="name" :rules="nameRules" label="Kategorie"/>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" type="submit">
            Speichern
          </v-btn>
          <v-btn color="default" @click="closeDialog()">
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
// ==================== [CSS] ===============================

// ==================== [JS] ================================
import SessionApi from "@/common/api/SessionApi";
import {eventBus} from "@/common/eventBus";
// ==================== [VUE COMPONENTS] ====================

export default {
  name: "CategoryCreate",
  data() {
    return {
      formValid: false,
      name: '',
      nameRules: [
        v => !!v || 'Bitte geben Sie Ihre Kategorie ein',
      ],
      dialog: false,
    }
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate())
        return null;

      const response = await this.axios({
        method: 'POST',
        url: SessionApi.category(),
        data: {
          name: this.$data.name
        },
      }).catch(() => {

      });

      if (response.status === 200) {
        this.$refs.form.reset();
        this.$data.dialog = false;
        eventBus.$emit('categoryForm', 'Erfolgreich erstellt');
      }
    },
    closeDialog() {
      this.$refs.form.reset();
      this.$data.dialog = false;
    },
  },
}
</script>

<style scoped>

</style>

<!--GET = Liste-->
<!--GET?id= = Einzelenes Element-->
<!--POST = Erstellen-->
<!--PUT?id = Bearbeiten-->
<!--DELETE?id = Löschen-->