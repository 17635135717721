import Vue from "vue";
import VueRouter from "vue-router";
import ProductList from "@/components/products/ProductList";
import LandingPage from "@/components/LandingPage";
import Login from "@/components/login/Login";
import Dashboard from "@/components/session/Dashboard";
import LoginManager from "@/common/utils/security/LoginManager";
import Category from "@/components/session/category/Category";
import Product from "@/components/session/product/Product";
import AboutMe from "@/components/session/aboutMe/AboutMe";
import UserList from "@/components/session/user/UserList";
import Privacy from "@/components/session/privacy/Privacy";
import Imprint from "@/components/session/imprint/Imprint";

Vue.use(VueRouter);

const vueRouter = new VueRouter({
    mode: "history",
    routes: [
        {path: "/", name: 'landingPage', component: LandingPage, meta: {noAuthRequired: true, title: 'Startseite'}},
        {path: "/produkte", name: 'products', component: ProductList, meta: {noAuthRequired: true, title: 'Produkte'}},
        {path: "/login", name: 'login', component: Login, meta: {noAuthRequired: true, title: 'Startseite'}},
        {path: "/datenschutz", name: 'privacy', component: Privacy, meta: {noAuthRequired: true, title: 'Datenschutz'}},
        {path: "/impressum", name: 'imprint', component: Imprint, meta: {noAuthRequired: true, title: 'Impressum'}},
        {path: "/dashboard", name: "dashboard", component: Dashboard},
        {path: "/category", name: "categoryList", component: Category},
        {path: "/product", name: "productList", component: Product},
        {path: "/aboutMe", name: "aboutMe", component: AboutMe},
        {path: "/user", name: "userList", component: UserList},
    ]
});

vueRouter.beforeEach((to, from, next) => {
    const title = to.meta.hasOwnProperty("title") ? to.meta.title : "";
    document.title = `${title} - Geo-Agentur`;
    if(to.meta.hasOwnProperty('noAuthRequired')) {
        next();
        return;
    }

    if(!LoginManager.isLoggedIn()) {
        next({name: "login"});
        return;
    } else {
        next();
    }
});

vueRouter.afterEach(() => {
    if(LoginManager.isLoggedIn())
        LoginManager.setAccessToken(LoginManager.getAccessToken());
});

export default vueRouter;