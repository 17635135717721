export default class SweetAlertConfig {

    //================= [PROTECTED METHODS] ==========================

    //================= [PUBLIC METHODS] =============================

    /**
     * Success config
     *
     * @returns {{icon: string, confirmButtonColor: string}}
     */
    static success() {
        return {
            icon: "success",
            confirmButtonColor: "#a5dc86",
        };
    }

    /**
     * Confirm config
     *
     * @param {string} confirmText
     * @param {string} cancelText
     * @returns {any}
     */
    static confirm(confirmText, cancelText = "Abbrechen") {
        return {
            icon: "success",
            confirmButtonColor: "#a5dc86",
            confirmButtonText: confirmText,
            cancelButtonText: cancelText,
            showCancelButton: true,
        };
    }

    /**
     * Warning config
     *
     * @param confirmText
     * @param cancelText
     * @returns {{allowOutsideClick: (function(): boolean), icon: string, confirmButtonColor: string, confirmButtonText, cancelButtonText: string, showCancelButton: boolean}}
     */
    static warning(confirmText, cancelText = "Abbrechen") {
        return {
            icon: "warning",
            confirmButtonColor: "#F8BB86",
            confirmButtonText: confirmText,
            cancelButtonText: cancelText,
            showCancelButton: true,
        };
    }

    static attention() {
        return {
            icon: "warning",
            confirmButtonColor: "#F8BB86",
            confirmButtonText: "Ok",
        };
    }

    /**
     * Delete config
     *
     * @param confirmText
     * @param cancelText
     * @returns {{allowOutsideClick: (function(): boolean), icon: string, confirmButtonColor: string, confirmButtonText: string, cancelButtonText: string, showCancelButton: boolean}}
     */
    static delete(confirmText = "Ja, löschen", cancelText = "Abbrechen") {
        return {
            icon: "error",
            confirmButtonColor: "#DD6B55",
            confirmButtonText: confirmText,
            cancelButtonText: cancelText,
            showCancelButton: true,
        };
    }

    /**
     * Error config
     *
     * @returns {{icon: string, confirmButtonColor: string, confirmButtonText: string}}
     */
    static error() {
        return {
            icon: "error",
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Ok",
        };
    }

    /**
     * Info config
     *
     * @param confirmText
     * @param cancelText
     * @returns {{icon: string, confirmButtonColor: string, confirmButtonText, cancelButtonText: string, showCancelButton: boolean}}
     */
    static info(confirmText, cancelText = "Abbrechen") {
        return {
            icon: "info",
            confirmButtonColor: "#C9DAE1",
            confirmButtonText: confirmText,
            cancelButtonText: cancelText,
            showCancelButton: true,
        };
    }

    //================= [PRIVATE METHODS] ============================
}