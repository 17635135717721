<template>
  <v-container>
    <v-row class="pt-16">
      <v-col class="text-center" cols="12">
        <h2>Meine Partner</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" lg="2" offset-lg="2">
        <a href="https://www.stonex.de/" target="_blank">
          <v-img id="stonexImg" src="https://geo-agentur.de/assets/images/partner/stonex.png" contain></v-img>
        </a>
      </v-col>
      <v-col cols="12" sm="6" lg="2">
        <a href="https://sokkia.com/" target="_blank">
          <v-img id="sokkiaImg" src="https://geo-agentur.de/assets/images/partner/sokkia.png" contain></v-img>
        </a>
      </v-col>
      <v-col cols="12" sm="6" lg="2">
        <a href="https://www.handheldgroup.com/de/" target="_blank">
          <v-img id="handheldImg" src="https://geo-agentur.de/assets/images/partner/handheld.png" contain></v-img>
        </a>
      </v-col>
      <v-col cols="12" sm="6" lg="2">
        <a href="https://de.getac.com" target="_blank">
          <v-img id="getacImg" src="https://geo-agentur.de/assets/images/partner/getac.png" contain></v-img>
        </a>
      </v-col>
    </v-row>
    <v-row class="pb-16">
      <v-col cols="12" sm="6" md="4" lg="2" offset-lg="3">
        <a href="https://www.geo-fennel.de/" target="_blank">
          <v-img id="geo_fennel" src="https://geo-agentur.de/assets/images/partner/geo_fennel.png" contain></v-img>
        </a>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <a href="https://g-nestle.de/" target="_blank">
          <v-img id="gNestle" src="https://geo-agentur.de/assets/images/partner/gNestle.png" contain></v-img>
        </a>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <a href="http://www.nedo.com/" target="_blank">
          <v-img id="nedo" src="https://geo-agentur.de/assets/images/partner/nedo.png" contain></v-img>
        </a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Partner",
  data(){
    return {

    }
  }

}
</script>

<style scoped>

</style>