<template>
    <v-container class="mt-5 pl-3 pr-3">
        <v-row>
            <v-col cols="12">
                <v-text-field background-color="#ffffff" v-model="search" append-icon="mdi-magnify" label="Suche" single-line hide-details/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" xl="2">
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-checkbox class="mt-0 pt-0 mb-n4" label="Angebot" v-model="checkboxOffer"/>
                                <h2>Zustand</h2>
                                <v-checkbox class="mt-2 mb-n4" label="Neu" v-model="checkboxNew"/>
                                <v-checkbox class="mt-0 pt-0" label="Gebraucht" v-model="checkboxUsed"/>
                                <h2>Kategorien</h2>
                                <v-checkbox class="mt-2 mb-n4" label="Alle" v-model="checkboxAllCategories"/>
                                <v-checkbox class="mt-0 pt-0 mb-n4" v-for="(category, index) in categories" :key="category.id" :label="category.name " v-model="categoryCheckboxes[index].checked"/>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" xl="10">
                <v-data-table id="productList" :search="search" loading-text="Produkte werden geladen" :loading="isLoading" :items="items" :headers="headers">
                    <template v-slot:item.card="{item}">
                        <v-card elevation="3" class="mb-3 productListCard">
                            <v-row>
                                <v-col cols="12" xl="3">
                                    <template v-if="item.images.length > 0">
                                        <lingallery :items="item.images" :showThumbnails="false" :responsive="true"/>
                                    </template>
                                </v-col>
                                <v-col cols="12" xl="9">
                                    <v-card-title>
                                        {{ item.name }} <small class="ml-5">{{ item.category.name }}</small>
                                    </v-card-title>
                                    <v-card-title class="mt-n10">
                                        <small>
                                            <v-chip v-if="item.state == 0" label class="my-2" color="primary">
                                                Gebraucht
                                            </v-chip>
                                        </small>
                                        <small>
                                            <v-chip v-if="item.offer == 1" label class="my-2 white--text" color="red">
                                                Angebot
                                            </v-chip>
                                        </small>
                                    </v-card-title>
                                    <v-card-title :class="item.state == 0 || item.offer == 1 ? 'mt-n10' : 'mt-n5'">
                                        <small>
                                            <span v-html="renderPrice(item.offerPrice)" v-if="item.offer" class="mr-2 offerPrice"/>
                                            <span v-html="renderPrice(item.price)" :class="item.offer ? 'oldPrice' : ''"/>
                                            (zzgl. {{ item.tax }}% Mwst)
                                        </small>
                                    </v-card-title>
                                    <v-card-text v-html="item.description"/>
                                </v-col>
                                <v-col class="productCardFooter" cols="12" xl="12">
                                    <v-row>
                                        <v-col cols="12" xl="6">
                                            <v-btn v-if="item.pdfPath !== null" :href="item.pdfPath" target="_blank" outlined rounded color="primary" x-large>PDF</v-btn>
                                        </v-col>
                                        <v-col cols="12" xl="6" class="text-right">
                                            <modal :product="item"/>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// ==================== [CSS] ===============================

// ==================== [JS] ================================

// ==================== [VUE COMPONENTS] ====================
import Lingallery from 'lingallery';
import CommonApi from "@/common/api/CommonApi";
import Modal from "@/components/products/modal";

export default {
    name: "ProductList",
    components: {
        Modal,
        Lingallery
    },
    data() {
        return {
            search: '',
            isLoading: false,
            checkboxOffer: false,
            checkboxNew: true,
            checkboxUsed: true,
            checkboxAllCategories: true,
            categories: [],
            categoryCheckboxes: [],
            items: [],
            headers: [
                {
                    text: "",
                    value: "name",
                    "class": "d-none",
                    "cellClass": "d-none",
                    width: 1,
                    sortable: false,
                },
                {
                    text: "",
                    value: "offer",
                    "class": "d-none",
                    "cellClass": "d-none",
                    width: 1,
                    sortable: false,
                    filter: value => {
                        if(!this.$data.checkboxOffer)
                            return true;

                        return !!(this.$data.checkboxOffer && value);
                    }
                },
                {
                    text: "",
                    value: "state",
                    "class": "d-none",
                    "cellClass": "d-none",
                    width: 1,
                    sortable: false,
                    filter: value => {
                        if(this.$data.checkboxNew && value)
                            return true;
                        else if(this.$data.checkboxUsed && !value)
                            return true;

                        return false;
                    }
                },
                {
                    text: "",
                    value: "category.id",
                    "class": "d-none",
                    "cellClass": "d-none",
                    width: 1,
                    sortable: false,
                    filter: value => {
                        let filter = false;
                        this.$data.categoryCheckboxes.forEach((category) => {
                            if(value === category.id)
                                filter = category.checked;
                        });
                        if(filter)
                            return true;
                    }
                },
                {
                    text: "",
                    value: "description",
                    "class": "d-none",
                    "cellClass": "d-none",
                    width: 1,
                    sortable: false,
                },
                {
                    text: "",
                    value: "offerPrice",
                    "class": "d-none",
                    "cellClass": "d-none",
                    width: 1,
                    sortable: false,
                },
                {
                    text: "",
                    value: "card",
                    "class": "d-none",
                    sortable: false,
                }
            ]
        }
    },
    watch: {
        checkboxAllCategories(value) {
            this.$data.categoryCheckboxes.forEach((category) => {
                category.checked = value;
            });
        },
    },
    methods: {
        async loadProducts() {
            const response = await this.axios({
                method: 'GET',
                url: CommonApi.productList(),
            }).catch(() => {
            });

            if(response !== undefined && response.status === 200 && response.data !== null)
                this.$data.items = response.data;
        },
        async loadCategories() {
            const response = await this.axios({
                method: 'GET',
                url: CommonApi.categories(),
            }).catch(() => {
            });

            if(response !== undefined && response.status === 200 && response.data !== null) {
                const categoryList = response.data;
                categoryList.forEach((category) => {
                    this.$data.categoryCheckboxes.push({checked: true, id: category.id});
                });
                this.$data.categories = categoryList;
            }
        },
        toggleCategory(categoryId) {
            this.$data.categoryCheckboxes[categoryId].checked = !this.$data.categoryCheckboxes[categoryId].checked;
        },
        setItemGalleryIndex(itemId) {
            this.$data.itemGallery[itemId] = 0
        },
        renderPrice(price) {
            return `${Number(price).toFixed(2).toString().replace('.', ',')}€`;
        }
    },
    created() {
        this.loadProducts();
        this.loadCategories();
    }
}
</script>

<style scoped>

</style>