<template>
    <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on" @click="loadCategory()">
                <v-list-item-title>Bearbeiten</v-list-item-title>
            </v-list-item>
        </template>
        <v-card>
            <v-card-title class="text-h5 white--text primary">
                Kategorie bearbeiten
            </v-card-title>
            <v-form ref="form" v-model="formValid" @submit.prevent="submit()" v-if="category !== null">
                <v-card-text>
                    <v-text-field v-model="category.name" :rules="nameRules" label="Kategorie"/>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" type="submit">
                        Speichern
                    </v-btn>
                    <v-btn color="default" @click="closeDialog()">
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
// ==================== [CSS] ===============================

// ==================== [JS] ================================
import {eventBus} from "@/common/eventBus";
import SessionApi from "@/common/api/SessionApi";
// ==================== [VUE COMPONENTS] ====================

export default {
    name: "CategoryEdit",
    props: ['id'],
    data() {
        return {
            formValid: false,
            category: null,
            nameRules: [
                v => !!v || 'Bitte geben Sie Ihre Kategorie ein',
            ],
            dialog: false,
        }
    },
    methods: {
        async submit() {
            if(!this.$refs.form.validate())
                return null;

            const response = await this.axios({
                method: 'PUT',
                url: SessionApi.category(this.$data.category.id),
                data: this.$data.category,
            }).catch(() => {

            });

            if(response !== undefined && response.status === 200) {
                this.$refs.form.reset();
                this.$data.dialog = false;
                eventBus.$emit('categoryForm', 'Erfogreich bearbeitet');
            }
        },

        async loadCategory() {
            const response = await this.axios({
                method: 'GET',
                url: SessionApi.category(this.$props.id),
            }).catch(() => {

            });

            if(response !== undefined && response.status === 200) {
                this.$data.category = response.data;
                this.$data.dialog = true;
            }
        },
        closeDialog() {
            this.$refs.form.reset();
            this.$data.dialog = false;
        }
    }

}
</script>

<style scoped>

</style>