<template>
    <v-dialog v-model="dialog" width="700" persistent>
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" large v-bind="attrs" v-on="on" @click="loadCategory()">
                Produkt erstellen
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="text-h5 white--text primary">
                Neues Produkt erstellen
            </v-card-title>
            <v-form ref="form" v-model="formValid" @submit.prevent="submit()">
              <div class="d-flex justify-center" v-if="categoriesLoading">
                <v-progress-circular size="80" color="primary" indeterminate/>
              </div>
              <v-card-text v-else-if="!categoriesLoading && categoryItems.length > 0">
                    <v-select class="mt-5" :items="categoryItems" v-model="product.category.id" item-value="id" item-text="name" dense :rules="categoryRules" label="Kategorie"/>
                    <v-text-field v-model="product.name" :rules="nameRules" label="Produkt"/>
                    <v-text-field v-model="product.price" label="Preis" type="number"/>
                    <v-checkbox v-model="product.saled" label="Verkauft"></v-checkbox>
                    <v-select class="mt-5" :items="items" label="Zustand" dense v-model="product.state"></v-select>
                    <v-checkbox v-model="product.offer" label="Angebot"></v-checkbox>
                    <v-text-field v-if="product.offer" v-model="product.offerPrice" label="Angebotspreis" type="number"/>
                    <v-text-field v-model="product.tax" :rules="taxRules" label="Mehrwertsteuer" type="number"/>
                    <v-text-field v-model="product.order" :rules="orderRules" label="Reihenfolge" type="number"/>
                    <v-file-input class="mb-4" multiple v-model="images" small-chips accept="image/*" label="Bilder auswählen" @change="imageChanged()" :error-messages="imageErrors"/>
                    <v-file-input class="mb-7" v-model="pdf" small-chips accept="application/pdf" label="PDF auswählen" @change="pdfChanged()" :error-messages="pdfErrors"/>
                    <geo-editor v-model="product.description"/>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" :loading="loading" type="submit">
                        Speichern
                    </v-btn>
                    <v-btn color="default" @click="closeDialog()">
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
// ==================== [CSS] ===============================

// ==================== [JS] ================================
import SessionApi from "@/common/api/SessionApi";
import {eventBus} from "@/common/eventBus";
// ==================== [VUE COMPONENTS] ====================
import GeoEditor from "@/components/common/GeoEditor";

export default {
    name: "ProductCreate",
    components: {GeoEditor},
    data() {
        return {
            categoryItems: [],
            items: [{text: 'Gebraucht', value: 0}, {text: 'Neu', value: 1}, {text: 'Demo', value: 2}],
            loading: false,
            categoriesLoading: false,
            product: {
                category: {
                    id: null
                },
                name: "",
                description: "",
                price: '',
                offer: false,
                offerPrice: null,
                state: 1,
                saled: false,
                tax: 19,
                order: null,
            },
            pdf: null,
            images: [],
            imagesCount: 0,
            categoryRules: [
                v => !!v || 'Bitte geben Sie Ihre Kategorie ein',
            ],
            priceRules: [
                v => !!v || 'Bitte geben Sie Ihren Preis ein',
            ],
            taxRules: [
                v => !!v || 'Bitte geben Sie Ihren Preis ein',
            ],
            orderRules: [
                v => !!v || 'Bitte geben Sie eine Reihenfolge ein',
            ],
            descriptionRules: [
                v => !!v || 'Bitte geben Sie Ihren Preis ein',
            ],
            formValid: false,
            nameRules: [
                v => !!v || 'Bitte geben Sie Ihre Produktname ein',
            ],
            dialog: false,
            imageErrors: [],
            pdfErrors: [],
        }
    },
    methods: {
        async loadCategory() {
            this.$data.categoriesLoading = true;
            const response = await this.axios({
                method: 'GET',
                url: SessionApi.category(),
            }).catch(() => {
            });
          this.$data.categoriesLoading = false;
            if(response !== undefined && response.status === 200) {
                this.$data.categoryItems = response.data
            }
        },
        async submit() {
            if(!this.$refs.form.validate())
                return null;

            this.$data.loading = true;

            const formData = new FormData();
            for(let i = 0; i < this.$data.images.length; i++) {
                let file = this.$data.images[i];

                formData.append('images[' + i + ']', file);
            }
            formData.append('product', JSON.stringify(this.$data.product));
            if(this.$data.pdf !== null)
                formData.append('pdf', this.$data.pdf);

            const response = await this.axios({
                method: 'POST',
                url: SessionApi.products(),
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).catch(() => {

            });
            this.$data.loading = false;
            if(response !== undefined && response.status === 200) {
                this.$refs.form.reset();
                this.$data.dialog = false;
                eventBus.$emit('productFormSubmitted', 'Erfolgreich erstellt');
            }
        },
        closeDialog() {
            this.$refs.form.reset();
            this.$data.dialog = false;
        },
        imageChanged() {
            this.$data.imageErrors = [];
            if(this.$data.images.length > 0) {
                this.$data.images.forEach((file) => {
                    if(!file.type.includes('image'))
                        this.$data.imageErrors.push(`Dateiendung ${file.type} wird nicht unterstützt`);
                });
            }
        },
        pdfChanged() {
            this.$data.pdfErrors = [];
            if(this.$data.pdf !== null) {
                if(this.$data.pdf.type !== 'application/pdf')
                    this.$data.pdfErrors.push(`Dateiendung ${this.$data.pdf.type} wird nicht unterstützt`);
            }
        },
    },

}
</script>

<style scoped>

</style>

<!--GET = Liste-->
<!--GET?id= = Einzelenes Element-->
<!--POST = Erstellen-->
<!--PUT?id = Bearbeiten-->
<!--DELETE?id = Löschen-->
