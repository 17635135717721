<template>
    <v-container class="mt-5 pl-3 pr-3 pb-3">
        <v-row>
            <v-col cols="12" xl="6">
                <h1 class="font-weight-regular">Benutzer</h1>
            </v-col>
            <v-col cols="12" xl="6" class="text-right">
                <user-create />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-text-field background-color="#ffffff" v-model="search" append-icon="mdi-magnify" label="Suche" single-line
                              hide-details outlined/>
            </v-col>
        </v-row>
        <v-card class="mt-5">
            <v-data-table :items="userList" :headers="headers" :search="search" :loading="isLoading"
                          loading-text="Daten werden geladen...">
                <template v-slot:item.actions="{item}">
                    <v-menu left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on">
                                mdi-dots-vertical
                            </v-icon>
                        </template>
                        <v-list>
                            <v-list-item @click="deleteUser(item)">
                                <v-list-item-title>Löschen</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </v-data-table>
        </v-card>
        <v-snackbar timeout="2000" v-model="showSnackbar">{{ snackBarText }}</v-snackbar>
    </v-container>
</template>

<script>
// ==================== [CSS] ===============================

// ==================== [JS] ================================

// ==================== [VUE COMPONENTS] ====================

import SessionApi from "@/common/api/SessionApi";
import SweetAlertConfig from "@/common/utils/SweetAlertConfig";
import UserCreate from "@/components/session/user/UserCreate";
import {eventBus} from "@/common/eventBus";

export default {
    name: "UserList",
    components: {UserCreate},
    data(){
        return{
            snackBarText: '',
            showSnackbar: false,
            search: "",
            userList: [],
            isLoading: true,
            headers: [
                {
                    text: "Benutzername",
                    value: "username",
                },
                {
                    text: "Email",
                    value: "email",
                },
                {
                    text: "Rolle",
                    value: "role",
                },
                {
                    text: "",
                    value: "actions",
                    cellClass: "actionColumn",
                    sortable: false,
                }
            ],

        }
    },
    methods: {
        async getUserList() {
            const response = await this.axios({
                method: 'GET',
                url: SessionApi.user(),
            }).catch(() => {
            });

            if(response !== undefined && response.status === 200) {
                this.$data.userList = response.data;
                this.$data.isLoading = false;
            }
        },

        deleteUser(user) {
            this.$swal.fire({
                title: 'Benutzer löschen',
                html: `Sind Sie sich sicher das Sie <b>${user.username}</b> löschen möchten?`,
                ...SweetAlertConfig.delete(),
                preConfirm: async () => {
                    const response = await this.axios({
                        method: 'DELETE',
                        url: SessionApi.user(user.id),
                    }).catch(() => {
                    });
                    if (response !== undefined && response.status === 200) {
                        await this.getUserList();
                        eventBus.$emit('userFormSubmitted', 'Erfolgreich gelöscht')
                    }
                }
            });
        }
    },
    created() {
        this.getUserList()
    },
    mounted() {
        eventBus.$on('userFormSubmitted', (text) => {
            this.getUserList();
            this.$data.snackBarText = text;
            this.$data.showSnackbar = true;
        });
    }
}
</script>

<style scoped>

</style>