<template>
  <v-container class="pl-3 pr-3" fluid>
    <div :style="imageBackground">
      <div id="headerText" class="text-center">
        <h1>
          <strong class="white--text font-weight-regular">Herzlich Willkommen <span
              class="d-none d-md-block">bei der Geo-Agentur</span></strong>
        </h1>
        <p class="d-none d-md-block">
          <strong class="white--text font-weight-regular">Verkaufen, Vermieten und Service von Vermessungssysteme</strong>
        </p>
      </div>
    </div>
    <v-container>
      <br>
      <h2 class="text-center">Über mich</h2>
      <div v-html="aboutMeText"></div>
      <ContactForm/>
      <Partner class="pb-16"/>
    </v-container>
  </v-container>
</template>
<script>
// ==================== [CSS] ===============================

// ==================== [JS] ================================

// ==================== [VUE COMPONENTS] ====================

import ContactForm from "@/components/landingPage/ContactForm";
import Partner from "@/components/landingPage/Partner";
import CommonApi from "@/common/api/CommonApi";

export default {
  name: "LandingPage",
  components: {Partner, ContactForm},
  data() {
    return {
      imageBackground: {
        'background': `url(${require('@/assets/images/header.jpg')}) repeat scroll 0% 90% / cover`,
        'background-size': 'cover',
        'margin-top': '-18px',
        'height': '410px',
      },
      aboutMeText: ""
    };
  },
  methods: {
    async getAbout() {
      const response = await this.axios({
        method: 'GET',
        url: CommonApi.aboutMe(),
      }).catch(() => {

      });

      if (response.status === 200)
        this.$data.aboutMeText = response.data;
    }
  },
  created() {
    this.getAbout();
  },
}
</script>

<style scoped>

</style>