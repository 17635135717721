<template>
    <v-container class="mt-5 pl-3 pr-3 pb-3">
        <v-row>
            <v-col cols="12" xl="12">
                <h1 class="font-weight-regular">Über Mich</h1>
            </v-col>
        </v-row>
        <v-row v-if="aboutMe !== null">
            <v-form ref="form" v-model="formValid" @submit.prevent="submit()">
                <v-col cols="12">
                    <geo-editor v-model="aboutMe.text"/>
                    <v-btn class="mt-5" color="primary" :loading="formLoading" type="submit">Speichern</v-btn>
                </v-col>

            </v-form>
        </v-row>
        <v-snackbar timeout="2000" v-model="showSnackbar">{{ snackBarText }}</v-snackbar>
    </v-container>
</template>

<script>
// ==================== [CSS] ===============================

// ==================== [JS] ================================

// ==================== [VUE COMPONENTS] ====================
import GeoEditor from "@/components/common/GeoEditor";
import SessionApi from "@/common/api/SessionApi";

export default {
    name: "AboutMe",
    components: {GeoEditor},
    data() {
        return {
            snackBarText: '',
            showSnackbar: false,
            formValid: false,
            aboutMe: null,
            formLoading: false,
        }
    },
    methods: {
        async getAboutMe() {
            const response = await this.axios({
                method: 'GET',
                url: SessionApi.aboutMe(),
            }).catch(() => {
            });

            if(response !== undefined && response.status === 200) {
                this.$data.aboutMe = response.data;
            }
        },

        async submit() {
            this.$data.formLoading = true;
            const response = await this.axios({
                method: 'POST',
                url: SessionApi.aboutMe(),
                data: this.$data.aboutMe,
            }).catch(() => {

            });

            if(response !== undefined && response.status === 200) {
                this.$data.snackBarText = 'Erfolgreich gespeichert';
                this.$data.showSnackbar = true;
            }
            this.$data.formLoading = false;
        },
    },
    created() {
        this.getAboutMe();
    }
}
</script>

<style scoped>

</style>