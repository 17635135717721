<template>
    <v-dialog v-model="dialog" width="700" persistent>
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" large v-bind="attrs" v-on="on" @click="loadRole()">
                Benutzer erstellen
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="text-h5 white--text primary">
                Neuer Benutzer erstellen
            </v-card-title>
            <v-form ref="form" v-model="formValid" @submit.prevent="submit()">
                <v-card-text v-if="roleItems.length > 0">
                    <v-select class="mt-5" :items="roleItems" v-model="user.role.id" item-value="id" item-text="name" dense :rules="roleRules" label="Rolle"/>
                    <v-text-field v-model="user.username" :rules="usernameRules" label="Benutzername"/>
                    <v-text-field v-model="user.email" :rules="emailRules" label="Email" type="email"/>
                    <v-text-field v-model="user.password" :rules="passwordRules" label="Password" type="password"/>
                    <v-text-field v-model="user.repeat" :rules="repeatRules" label="Password wiederholen" type="password"/>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" :loading="loading" type="submit">
                        Speichern
                    </v-btn>
                    <v-btn color="default" @click="closeDialog()">
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
// ==================== [CSS] ===============================

// ==================== [JS] ================================

// ==================== [VUE COMPONENTS] ====================

import SessionApi from "@/common/api/SessionApi";
import {eventBus} from "@/common/eventBus";

export default {
    name: "UserCreate",
    data(){
        return{
            dialog: false,
            loading: false,
            roleItems: '',
            user:{
                role:{
                    id: '',
                },
                username: '',
                email: '',
                password: '',
                repeat: '',
            },
            usernameRules: [
                v => !!v || 'Bitte geben Sie einen Benutzer ein',
            ],
            emailRules: [
                v => !!v || 'Bitte geben Sie eine Email ein',
                v => /.+@.+\..+/.test(v) || 'Bitte geben Sie eine richtige Email an',
            ],
            passwordRules: [
                v => !!v || 'Bitte geben Sie ein Passwort ein',
            ],
            repeatRules: [
                v => !!v || 'Bitte geben Sie ein Passwort ein',
                v => this.$data.user.password === this.$data.user.repeat || 'Bitte geben Sie das gleiche Passwort ein'
            ],
            roleRules: [
                v => !!v || 'Bitte geben Sie eine Rolle für den Benutzer ein',
            ],
            formValid: false,
        }
    },
    methods:{
        async submit() {
            if(!this.$refs.form.validate())
                return null;

            this.$data.loading = true;

            const response = await this.axios({
                method: 'POST',
                url: SessionApi.user(),
                data: this.$data.user,
            }).catch(() => {

            });

            if(response !== undefined && response.status === 200) {
                this.$refs.form.reset();
                this.$data.loading = false;
                this.$data.dialog = false;
                eventBus.$emit('userFormSubmitted', 'Erfolgreich erstellt');
            }
        },

        closeDialog() {
            this.$refs.form.reset();
            this.$data.dialog = false;
        },

        async loadRole() {
            const response = await this.axios({
                method: 'GET',
                url: SessionApi.role(),
            }).catch(() => {

            });

            if(response !== undefined && response.status === 200) {
                this.$data.roleItems = response.data
            }
        },
    },
}
</script>

<style scoped>

</style>