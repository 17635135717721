export default class SessionApi {

    static category(id = null) {
        return `${SessionApi.ROOT_URL}/api/category${id != null ? `?id=${id}` : ''}`;
    }

    static products(id = null) {
        return `${SessionApi.ROOT_URL}/api/product${id != null ? `?id=${id}` : ''}`;
    }

    static user(id = null) {
        return `${SessionApi.ROOT_URL}/api/user${id != null ? `?id=${id}` : ''}`;
    }

    static role(id = null) {
        return `${SessionApi.ROOT_URL}/api/role${id != null ? `?id=${id}` : ''}`;
    }

     static aboutMe() {
        return `${SessionApi.ROOT_URL}/api/aboutMe`;
    }
}

SessionApi.ROOT_URL = process.env.NODE_ENV === "production" ? window.location.origin : "http://localhost:8000";
