<template>
    <v-app>
        <v-navigation-drawer v-model="drawer" absolute temporary app>
            <v-list nav dense>
                <v-list-item-group v-model="group">
                    <div class="pt-3" v-if="!isLogin">
                        <v-list-item>
                            <router-link class="mr-3" :to="{name: 'landingPage'}">
                                Startseite
                            </router-link>
                        </v-list-item>
                        <v-list-item>
                            <router-link class="mr-3" :to="{name: 'products'}">
                                Produkte
                            </router-link>
                        </v-list-item>
                        <v-list-item>
                            <router-link :to="{name: 'login'}">
                                Login
                            </router-link>
                        </v-list-item>
                    </div>
                    <div class="pt-3" v-else>
                        <v-list-item>
                            <router-link class="mr-3" :to="{name: 'dashboard'}">
                                Dashboard
                            </router-link>
                        </v-list-item>
                        <v-list-item>
                            <a @click="logout()">
                                Abmelden
                            </a>
                        </v-list-item>
                    </div>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar color="primary" absolute app>
            <v-container>
                <v-row>
                    <v-toolbar-title class="d-none d-lg-block">Geo-Agentur</v-toolbar-title>
                    <v-spacer/>
                    <div class="pt-3" v-if="!isLogin">
                        <router-link class="mr-3 d-none d-lg-inline" :to="{name: 'landingPage'}">
                            Startseite
                        </router-link>
                        <router-link class="mr-3 d-none d-lg-inline" :to="{name: 'products'}">
                            Produkte
                        </router-link>
                        <router-link class="d-none d-lg-inline" :to="{name: 'login'}">
                            Login
                        </router-link>
                    </div>
                    <div class="pt-3" v-else>
                        <router-link class="mr-3 d-none d-lg-inline" :to="{name: 'dashboard'}">
                            Dashboard
                        </router-link>
                        <a class="d-none d-lg-inline" @click="logout()">
                            Abmelden
                        </a>
                    </div>
                </v-row>
                <v-row class="mt-n1">
                    <v-toolbar-title class="d-block d-lg-none">Geo-Agentur</v-toolbar-title>
                    <v-spacer/>
                    <v-app-bar-nav-icon class="d-block d-lg-none" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
                </v-row>
            </v-container>
        </v-app-bar>
        <v-main>
            <router-view/>
        </v-main>
        <Footer/>
    </v-app>
</template>

<script>
// ==================== [CSS] ===============================
import "@/assets/sass/font/mdi/css/materialdesignicons.min.css";
import 'sweetalert2/dist/sweetalert2.min.css';

// ==================== [JS] ================================

// ==================== [VUE COMPONENTS] ====================

import Footer from "./components/footer/Footer";
import LoginManager from "@/common/utils/security/LoginManager";

export default {
    name: "App",
    components: {Footer},
    data() {
        return {
            drawer: false,
            group: null,
            isLogin: LoginManager.isLoggedIn(),
        }
    },
    watch: {
        group() {
            this.drawer = false
        },

        $route() {
            this.$data.isLogin = LoginManager.isLoggedIn();
        }
    },
    methods: {
        async logout() {
            LoginManager.removeCookies();
            this.$store.commit('logout');
            delete this.axios.defaults.headers.common["Authorization"];
            if(this.$router.currentRoute.name === 'landingPage')
                location.reload();
            else
                await this.$router.push({name: 'landingPage'});
        }
    },
}
</script>

<style lang="sass">
@import "assets/sass/application.sass"
</style>
