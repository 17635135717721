<template>
    <v-form ref="form" v-model="valid" @submit.prevent="submit()" class="pt-10">
        <v-row class="justify-center">
            <v-col cols="12" sm="6">
                <v-card>
                    <v-card-title id="cardTitle" class="justify-center mb-5">
                        <h2>Kontaktformular</h2>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-alert v-if="formSuccess" type="success">Kontaktformular erfolgreich gesendet</v-alert>
                            <v-alert v-if="formError" type="error">Ups ein Fehler ist aufgetreten, bitte versuchen Sie es zu einem späteren Zeitpunkt nocheinmal</v-alert>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field v-model="firstname" :rules="nameRules" label="Name *" required/>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="email" :rules="emailRules" label="E-mail *" required/>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="subject" :rules="subjectRules" label="Betreff *" required/>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea v-model="message" :rules="messageRules" label="Nachricht *" required/>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field class="mt-5" id="jCaptcha" label="Ergebnis" required :rules="captchaRules" :error-messages="captchaError"/>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn color="primary" type="submit">
                                        Absenden
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>

import CommonApi from "@/common/api/CommonApi";
import JCaptcha from "js-captcha";

export default {
    name: "ContactForm",
    data() {
        return {
            valid: false,
            formSuccess: false,
            formError: false,
            firstname: '',
            nameRules: [
                v => !!v || 'Bitte geben Sie Ihren Namen ein',
            ],
            subject: '',
            subjectRules: [
                v => !!v || 'Bitte geben Sie einen Betreff ein',
            ],
            email: '',
            emailRules: [
                v => !!v || 'Bitte geben Sie eine Email an',
                v => /.+@.+/.test(v) || 'Bitte geben Sie eine Richtige E-Mail an',
            ],
            message: '',
            messageRules: [
                v => !!v || 'Bitte geben Sie eine Nachricht ein',
            ],
            captcha: "",
            captchaValid: false,
            captchaRules: [
                v => !!v || 'Bitte tragen Sie ein Ergebnis ein',
            ],
            captchaError: [],
        };
    },
    methods: {
        async submit() {
            this.$data.formSuccess = false;
            this.$data.formError = false;
            this.$data.captcha.validate();
            if(!this.$refs.form.validate() || !this.$data.captchaValid)
                return null;

            const response = await this.axios({
                method: 'POST',
                url: CommonApi.contactForm(),
                data: {
                    firstname: this.$data.firstname,
                    subject: this.$data.subject,
                    email: this.$data.email,
                    message: this.$data.message,
                }
            }).catch(() => {
                this.$data.formSuccess = false;
                this.$data.formError = true;
            });

            if(response.status === 200) {
                this.$refs.form.reset();
                this.$data.formError = false;
                this.$data.formSuccess = true;
                setTimeout(()=>{
                    this.$data.formSuccess = false;
                }, 2000)
            }
        },
        renderCaptcha() {
            this.$data.captcha = new JCaptcha({
                el: "#jCaptcha",
                canvasClass: "jCaptchaCanvas",
                canvasStyle: {
                    width: 100,
                    height: 30,
                    textBaseline: "top",
                    font: "20px Roboto, sans-serif",
                    textAlign: "left",
                    fillStyle: "#333",
                },
                requiredValue: "",
                callback: (response, $captchaInputElement, numberOfTries) => {
                    this.$data.captchaError = [];
                    if(response === "success") {
                        this.$data.captchaValid = true;
                    } else {
                        this.$data.captchaValid = false;
                        this.$data.captchaError.push("Das Ergebnis ist nicht korrekt");
                    }
                }
            });
        }
    },
    mounted() {
        this.renderCaptcha();
    }
}
</script>

<style scoped>

</style>