import Cookies from "js-cookie";

export default class LoginManager {

    static isLoggedIn() {
        return Cookies.get("accessToken") !== undefined && Cookies.get("accessToken") !== "undefined";
    }

    static removeCookies() {
        Cookies.remove("accessToken");
    }

    static getAccessToken() {
        return Cookies.get("accessToken");
    }

    static setAccessToken(accessToken) {
        const expireData = new Date();
        expireData.setHours(expireData.getHours() + 4);
        Cookies.set("accessToken", accessToken, {expires: expireData, SameSite: "Strict", secure: true});
    }
}